'use strict';

exports.__esModule = true;
exports.default = makePath;
function makePath(strings) {
  for (var _len = arguments.length, values = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    values[_key - 1] = arguments[_key];
  }

  var path = strings.reduce(function (str, next) {
    var value = values.shift();
    return str + (value == null ? '' : value) + next;
  });

  return path.replace(/^\./, '');
}
module.exports = exports['default'];